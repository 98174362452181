import React from "react"
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import CheckoutPage from './components/CheckoutPage'


export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path = "/feature-film">
          <CheckoutPage submissionType = "feature-film"/>
        </Route>
        <Route exact path = "/short-film">
          <CheckoutPage submissionType = "short-film"/>
        </Route>
        <Route exact path = "/student-film">
          <CheckoutPage submissionType = "student-film"/>
        </Route>
      </Switch>
      </Router>
    

    // "/short-film"
    // 
    // "/student-film"
  );
}
