const createPaymentIntent = (submissionType, options) => {
return window
.fetch(`https://us-central1-festiv-fd5c6.cloudfunctions.net/payments/create-payment-intent/` + submissionType, {
  // .fetch("http://localhost:5000/festiv-fd5c6/us-central1/payments/create-payment-intent/" + submissionType, {
  method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(options)
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        console.log("API error:", { data });
        throw new Error("PaymentIntent API Error");
      } else {
        return data.client_secret;
      }
    });
};

const getProductDetails = (submissionType, options) => {
  return window
    .fetch(`https://us-central1-festiv-fd5c6.cloudfunctions.net/payments/product-details/` + submissionType, {
      // .fetch("http://localhost:5000/festiv-fd5c6/us-central1/payments/product-details/" + submissionType, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        console.log("API error:", { data });
        throw Error("API Error");
      } else {
        return data;
      }
    });
};

const getPublicStripeKey = options => {
  return window
    .fetch(`https://us-central1-festiv-fd5c6.cloudfunctions.net/payments/public-key`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        console.log("API error:", { data });
        throw Error("API Error");
      } else {
        return data.publicKey;
      }
    });
};

const api = {
  createPaymentIntent,
  getPublicStripeKey: getPublicStripeKey,
  getProductDetails: getProductDetails
};

export default api;
