import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./CheckoutForm.css";
import api from "../api";

export default function CheckoutForm(props) {
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  let PROJECT_TITLE_SNIPPET; 
  if(props.submissionType === "feature-film") {
    PROJECT_TITLE_SNIPPET = "Feature Length";
  }
  if(props.submissionType === "short-film") {
    PROJECT_TITLE_SNIPPET = "Short Length";
  }
  if(props.submissionType === "student-film") {
    PROJECT_TITLE_SNIPPET = "Student ";
  }

  // CUSTOM FIELDS 
  const [filmName, setFilmName] = useState("")
  const [submitterName, setSubmitterName] = useState("")

  useEffect(() => {
    // Step 1: Fetch product details such as amount and currency from
    // API to make sure it can't be tampered with in the client.
    api.getProductDetails(props.submissionType).then((productDetails) => {
      setAmount(productDetails.amount / 100);
      setCurrency(productDetails.currency);
    });

    // Step 2: Create PaymentIntent over Stripe API
    api
      .createPaymentIntent(props.submissionType)
      .then((clientSecret) => {
        setClientSecret(clientSecret);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    // Step 3: Use clientSecret from PaymentIntent and the CardElement
    // to confirm payment with stripe.confirmCardPayment()
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: ev.target.name.value,
        },
      },
      receipt_email: ev.target.email.value,
      // metadata: {'film_title' : ev.target.filmTitle.value}
    });

    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`);
      setProcessing(false);
      console.log("[error]", payload.error);
    } else {
      setError(null);
      setSucceeded(true);
      setProcessing(false);
      setMetadata(payload.paymentIntent);
      fetch("https://us-central1-festiv-fd5c6.cloudfunctions.net/api/saveFilmSubmissionPayment", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "purchaseId": payload.paymentIntent.id,
          "submitterName": submitterName,
          "filmTitle": filmName,
          "receiptEmail": payload.paymentIntent.receipt_email,
          "submissionType": props.submissionType,
        })
      })
      .then(()=>{
        window.location.href = "http://festivmedia.com/payment-success"
      })
      // console.log("[PaymentIntent]", payload.paymentIntent);
    }
  };

  const renderSuccess = () => {
    return (
      <div className="sr-field-success message">
		<h1>Your payment is processing...</h1>
      </div>
    );
  };

  const renderForm = () => {
    const options = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };

    return (
      
      <div>
        <h1 style = {{marginBottom:"20px"}}>Submission Fee: {PROJECT_TITLE_SNIPPET} Project</h1>
      <form onSubmit={handleSubmit}>
        <h3>
          {currency.toLocaleUpperCase()}{" "}
          {amount.toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })}{" "}
        </h3>
        <h4>A one-time fee for {PROJECT_TITLE_SNIPPET} Projects to be considered for their addition to the Festiv platform.</h4>

        <div className="sr-combo-inputs">
          <div className="sr-combo-inputs-row">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Full Name"
              autoComplete="cardholder"
              className="sr-input"
              onChange = {(ev)=> {setSubmitterName(ev.target.value)}}
            />
          
          </div>
          <input
              type="text"
              id="filmTitle"
              name="filmTitle"
              placeholder="Film Title"
              className="sr-input"
              onChange = {(ev)=> {setFilmName(ev.target.value)}}
            />
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              className="sr-input"
            />

          <div className="sr-combo-inputs-row">
            <CardElement
              className="sr-input sr-card-element"
              options={options}
            />
          </div>
        </div>

        {error && <div className="message sr-field-error">{error}</div>}

        <button
          className="btn"
          disabled={processing || !clientSecret || !stripe}
        >
          {processing ? "Processing…" : "Pay"}
        </button>
      </form>
      </div>
    );
  };

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div style = {{display:"none"}} className="sr-form-row" />
        {succeeded ? renderSuccess() : renderForm()}
      </div>
    </div>
  );
}
