import CheckoutForm from "./CheckoutForm";
import React, {useEffect} from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ProductPreview from "../product-preview.png" 
import FestivLogo from "../festiv-logo.png"
import api from "../api";
import "../App.css";
const stripePromise = api.getPublicStripeKey().then(key => loadStripe(key));

export default function CheckoutPage(props) {
  
    return(
        <div className="App">
      <div className="sr-root">
        <div className="sr-main">
          <header style = {{marginBottom: "0px"}}className="sr-header">
            <img style= {{width: "85px"}} src= {FestivLogo}/>
          </header>
          <Elements stripe={stripePromise}>
            <CheckoutForm submissionType = {props.submissionType}/>
          </Elements>
        </div>

        <div className="sr-content">
          <div>
            <img
              alt=""
              src= {ProductPreview}
              width="350px"
            />
            <h4 style = {{textAlign: "center"}}>Accepted films are streamed worldwide.</h4>
          </div>
        </div>
      </div>
    </div>
    ) 
} 